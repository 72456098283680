.imageButton {
  background: none;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;

  img,
  svg {
    height: 100%;
    width: 100%;
  }
}
