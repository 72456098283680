@use "sass:map";

@use "~rivals/styles/mixins" as *;

@use "~rivals/styles/variables" as vars;

@use "~rivals/styles/exports.module.scss" as exports;

$primary-card-widths: (
  "desktop": 500px,
  "tablet": 336px,
  "regular-mobile": 324px,
  "small-mobile": 200px,
);
$secondary-card-widths: (
  "desktop": 216px,
  "tablet": 336px,
  "regular-mobile": 324px,
  "small-mobile": 282px,
);
$secondary-slide-max-height: 274px;

.headlineNewsWrapper {
  // To ensure the carousel articles reach the edge of the screen to indicate swipability
  @include sizes-ending-with($screen-tablet-max) {
    margin-right: -$spacing-12;
  }

  :global {
    .ant-carousel {
      // make sure the following overwrites stay under .ant-carousel

      .slick-list .slick-slide {
        // with `variableWidth` prop react-slick automatically adds pointer-events: none
        // which disables the card links click, we want the cards to be clickable
        pointer-events: auto;
      }

      .slick-dots-bottom {
        align-items: center;
        bottom: 0;
        height: $carousel-arrows-height;
        justify-content: flex-start;
        position: relative;
        width: fit-content;
        @include sizes-ending-with($screen-tablet-max) {
          justify-content: center;
          margin: 0;
          width: 100%;
        }
      }
    }
  }
}

.primarySlide {
  padding-right: $spacing-16;

  .contentCard {
    max-width: 100%;
    width: map.get($primary-card-widths, "desktop");

    img {
      max-height: calc(
        #{map.get($primary-card-widths, "desktop")} * 0.75
      ); // to maintain an aspect ration of 3/4
      max-width: map.get($primary-card-widths, "desktop");
    }

    > div {
      // overrides ContentCard.detailsWrapper on desktop
      padding: $spacing-16 0;
    }
  }
  @include sizes-ending-with($screen-tablet-max) {
    .contentCard {
      width: map.get($primary-card-widths, "tablet");

      > div {
        // overrides ContentCard.detailsWrapper on tablet
        padding: $spacing-12 0;
      }
    }
  }
  @include sizes-ending-with($screen-mobile-max) {
    .contentCard {
      width: map.get($primary-card-widths, "regular-mobile");
    }
  }
  @include sizes-ending-with($screen-sm-mobile-max) {
    margin-right: 0 $spacing-4;

    .contentCard {
      width: map.get($primary-card-widths, "small-mobile");
    }
  }
}

.secondarySlide {
  padding-right: $spacing-16;

  .contentCard {
    max-height: $secondary-slide-max-height;
    max-width: 100%;
    min-height: 250px;
    width: map.get($secondary-card-widths, "desktop");

    &:last-child {
      margin-bottom: 0;
    }

    &:not(:last-child) {
      margin-bottom: $spacing-48;
    }
    @include sizes-between($screen-tablet-min, $screen-tablet-max) {
      &:not(:last-child) {
        margin-bottom: $spacing-12;
      }
    }
    @include sizes-starting-with($screen-sm-desktop-min) {
      height: $secondary-slide-max-height;
    }

    > div {
      // overrides secondary cards ContentCard.detailsWrapper on desktop
      padding: $spacing-8 0;

      > div > div {
        div,
        span {
          @include font-primary-body-B6;
          @include sizes-ending-with($screen-tablet-max) {
            @include font-primary-body-B7;
          }
        }
      }
    }

    h3 {
      // overrides secondary cards ContentCard.title on desktop
      @include font-primary-bold-SB4;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-height: 20px;
      margin-bottom: $spacing-8;
      overflow: hidden;
      text-overflow: ellipsis;
      @include sizes-ending-with($screen-tablet-max) {
        @include font-primary-bold-SB5;
      }
    }
  }
  @include sizes-ending-with($screen-tablet-max) {
    .contentCard {
      max-height: 100%;
      min-height: 100%;
      width: map.get($secondary-card-widths, "tablet");

      > div {
        // overrides secondary cards ContentCard.detailsWrapper on tablet
        padding: 0 $spacing-8;
      }
    }
  }
  @include sizes-ending-with($screen-mobile-max) {
    .contentCard {
      width: map.get($secondary-card-widths, "regular-mobile");
    }
  }
  @include sizes-ending-with($screen-sm-mobile-max) {
    margin: 0 $spacing-4;

    .contentCard {
      width: map.get($secondary-card-widths, "small-mobile");
    }
  }
}

.loadingContainer {
  @include sizes-ending-with($screen-lg-mobile-max) {
    align-items: center;
    display: flex;
    height: 430px; // Approximation of content size
    justify-content: center;
  }
}

.spinner {
  height: 100%;
}
