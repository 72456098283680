@use "~rivals/styles/exports.module.scss" as exports;

.controlArrow {
  bottom: 13px;
  height: auto;
  left: unset;
  margin: 0;
  max-height: max-content;
  max-width: max-content;
  min-height: min-content;
  min-width: min-content;
  position: absolute;
  right: 0;
  top: unset;
  width: auto;

  &.prevArrow {
    bottom: 14.3px;
    right: 50px;
    transform: rotate(-90deg);
  }

  &.nextArrow {
    transform: rotate(90deg);
  }
}
